.control-bar {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #ececec;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
    z-index: 900;
    translate: -50%;
    transition: transform 0.3s ease;
    color: #000;
  }
  
  .control-bar button {
    flex: 1 1;
    font-size: 12px;
    font-weight: 800;
    align-items: center;
    background: #fff;
    border: none;
    margin: 4px;
    border-bottom: 1px solid #b5b5b5;
    border-radius: 5px;
    box-shadow: 0 0 3px -1px rgba(0, 0, 0, .3);
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    height: 40px;
    justify-content: center;
    padding: 5px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    color: #000;
  }

  hr{
    height: 2px;
    width: 100%;
    border: #f4f4f4;
    background-color: #f4f4f4;
  }

  @media (min-width: 580px){
    .control-bar {
        display: none;
    }
  }
  