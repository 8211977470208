.controls-container {
    position: fixed;
    bottom: 25px;
    right: 35px;
    color: #555;
    max-height: calc(100vh - 50px);
    overflow-y: auto;
}

.controls-container-alt {
    position: fixed;
    bottom: 25px;
    left: 35px;
    color: #555;
    max-height: calc(100vh - 50px);
    overflow-y: auto;
}

.controls-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
    gap: 12px;
}

.control-item {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex-direction: column-reverse;
    gap: 5px;
}

.control-item-alt {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column-reverse;
    gap: 5px;
}

.controls{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 2px;
    font-size: 12px;
    text-transform: uppercase;
    color: black;
    border: #555 1px solid;
    min-width: 25px;
    border-radius: 5px;
    padding: 5px;

}

.control-icon {
    font-size: 8px;
}

.control-description p {
    margin: 0;
    font-size: 14px;
}

.control-description p strong {
    display: block;
    font-size: 10px;
    color: #555;
}

.mobile-controls {
    text-align: center;
    position: fixed;
    bottom: 25px;
    left: 0;
    right: 0;
    color: #000;
}

.mobile-controls-button {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 30px;
    display: inline-block;
    outline: none;
    font-size: 12px;
    text-transform: uppercase;
    border: #555 1px solid;
    min-width: 25px;
    color: #000;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: auto;
    padding: 20px;
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    max-height: 90vh;
    overflow-y: auto;
    width: 90%;
    max-width: 500px;
}

.close-modal {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 10px;
    cursor: pointer;
    font-size: 16px;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    display: flex;
    place-items: center;
    cursor: pointer;
    outline: none;
    text-transform: uppercase;
    border: #555 1px solid;
    color: #555;
}

.modal-controls-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 50px;
}

.word-count-display {
    position: fixed;
    bottom: 60px;
    left: 50%;
    transform: translateX(-50%);
    background: rgba(255, 255, 255, 0.95);
    padding: 6px 12px;
    border-radius: 15px;
    box-shadow: 0 -1px 6px rgba(0, 0, 0, 0.1);
    font-size: 14px;
    color: #666;
    z-index: 1001;
    white-space: nowrap;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
}

.word-count-display.visible {
    opacity: 1;
    visibility: visible;
}

@media (max-width: 1200px) {
    .control-item {
        flex-direction: row-reverse;
        align-items: center;
    }

    .control-item-alt {
        flex-direction: row-reverse;
    }
    
}

@media (max-width: 580px){
    .controls-container {
        display: none;
    }

    .controls-container-alt {
        display: none;
    }

    .mobile-controls {
        display: none;
    }
    
}