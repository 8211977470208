.avatar-container {
    position: fixed;
    top: 25px;
    right: 35px;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    overflow: hidden;
    border: 2px solid #000000;
    cursor: pointer;
}

.avatar-image {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}
