.splash-screen {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    background-color: #f4f4f4;
    background: 
          linear-gradient(#f4f4f4, #f4f4f4),
          url('../../Assets/papper-texture.jpg') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
    background-size: 100%;
    font-family: Arial, sans-serif;
    z-index: 1000;
    transition: opacity 1s ease-out;
}

.splash-screen.fade-out {
    opacity: 0;
}

.blinking-cursor {
    width: 1px;
    height: 100px;
    background-color: #333;
    animation: blink 1s infinite;
}

.i{
    font-size: 90px;
    font-weight: 500;
    color: #000000;
    margin-right: 2px;
}

.type{
    font-size: 90px;
    font-weight: 500;
    color: #cccccc;
    margin-left: 2px;
}

@keyframes blink {
    0%, 50% {
        opacity: 1;
    }
    50.01%, 100% {
        opacity: 0;
    }
}
