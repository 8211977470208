@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap");

/* optimisation */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  border-collapse: collapse;
}

*::selection {
  background-color: transparent;
  user-select: none;
}

*::-webkit-scrollbar-thumb:horizontal,
*::-webkit-scrollbar:horizontal {
  display: none;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Courier New", Courier, monospace;
  background-color: #f4f4f4;
  background: linear-gradient(#f4f4f480, #f4f4f480),
    url("./Assets/papper-texture.jpg") no-repeat center center fixed;
  background-size: cover;
  background-size: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  height: 100dvh;
}

.typewriter-restriction {
}

.typewriter-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 960px;
  padding: 50px 0;
  position: absolute;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  transition: all 1.2s cubic-bezier(0.23, 1, 0.32, 1);
}

.typewriter-container:has(.typewriter-input[style*="font-size: 25px"]) {
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.app-container {
  min-height: 100vh;
  background-color: #ffffff;
  color: #333333;
}

.word-count {
  position: absolute;
  background: #ffffff;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  color: #666;
  pointer-events: none;
  z-index: 1000;
  transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  opacity: 0;
  visibility: hidden;
}

.word-count.visible {
  opacity: 1;
  visibility: visible;
}

.typewriter-wrapper {
  position: relative;
  width: 100%;
}

.typewriter-input-wrapper {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.typewriter-suggestion {
  position: absolute;
  top: 0;
  color: #ccc;
  pointer-events: none;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  z-index: 0;
  width: 100%;
  transition: all 0s linear;
}

.typewriter-placeholder {
  color: #ccc;
}

.typewriter-input {
  position: relative;
  background: transparent;
  outline: none;
  border: none;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  z-index: 1;
  color: #000;
  width: 100%;
  transition: font-size 1.2s cubic-bezier(0.23, 1, 0.32, 1);
}

.typewriter-input::selection {
  background: rgba(0, 0, 0, 0.1);
}

.typewriter-input:focus {
  outline: none;
  box-shadow: none;
}

h1.logo {
  position: fixed;
  font-size: 35px;
  top: 25px;
  left: 35px;
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-thumb {
  background-color: #000;
}

::-webkit-scrollbar-track {
  background-color: #f4f4f4;
}

@media (max-width: 1200px) {
  body {
    background-size: 200%;
  }

  .typewriter-container {
    width: 90%;
  }
}

@media (max-width: 768px) {
  .typewriter-container {
    padding: 0;
    top: 35%;
    width: 100%;
  }

  .typewriter-input {
    font-size: 25px !important;
    transition: font-size 1.2s cubic-bezier(0.23, 1, 0.32, 1);
  }

  .typewriter-suggestion {
    font-size: 25px !important;
    transition: font-size 0s linear;
  }
}
